@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
}
body {
  background-color: #f3f8fd !important;
  overflow-x: hidden !important;
}
/* Add this to your CSS file */
.transparent-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; /* Set background to transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.create-model {
  font-size: 14px;
  font-weight: 500;
}

.data-input {
  width: 86px;
  border-radius: 26px;
}

.models-title {
  font-size: 18px;
  font-weight: 500;
}

.teacher-title {
  font-size: 12px;
  font-weight: 500;
}

.teacher-name {
  font-size: 14px;
  font-weight: 500;
}

.model-title {
  font-size: 18px;
  font-weight: 500;
}

.model-student-label {
  font-size: 12px;
  font-weight: 400;
}

.model-student-name {
  font-size: 16px;
  font-weight: 600;
}

.range {
  font-size: 12px;
  font-weight: 400;
}

.otp-label {
  font-size: 14px;
  font-weight: 400;
}

.timeout-label {
  font-size: 12px !important;
  font-weight: 500;
}

.timeout-input {
  width: 220px;
  border-radius: 26px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  height: 41px;
}

.policies-label {
  font-size: 16px;
  font-weight: 500;
}

.policies-label-sub {
  font-size: 14px;
  font-weight: 500;
}

.notification-input {
  border-radius: var(--spacing-spacing-xs, 5px);
  border: 0.5px solid #939393;
  resize: none;
}

.password-input {
  width: 70px;
  height: 40px;
}

.password-settings-label {
  font-size: 12px;
  font-weight: 500;
}

.notification-input:focus-visible {
  outline: none !important;
}

.add-document-title {
  font-size: 21px;
  font-weight: 600;
}

.add-document-label {
  font-size: 14px;
  font-weight: 400;
}

.focus-mode-text {
  font-size: 10px;
}

.focus-mode-upload-text {
  font-size: 18px;
  font-weight: 400;
}

.focus-mode-upload {
  height: 103px;
  border-radius: var(--radius-rounded, 3px);
  border: 2px dashed var(--black, #000);
  display: flex;
  justify-content: center;
  align-items: center;
}

.limit-message {
  font-size: 12px;
  font-weight: 400;
}

.institute-limit {
  border-radius: 38px;
  background: var(--white, #fff);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  width: 72px;
  border-color: unset;
}

.favorite-dropdown {
  right: 0;
}

.transparent-content {
  background-color: transparent; /* Set background to transparent for the modal content */
  border: none; /* Remove any borders or box shadows */
}

.progressBar {
  width: 150px;
  height: 15px;
  background-color: #e9e9e9;
  border-radius: 20px;
}
.innerProgress {
  border-radius: 20px;
  height: 15px;
}
.displayRowProgress {
  display: flex;
  flex-direction: row;
  margin: 20px;
}
.percentage {
  margin-left: 10px;
}

a {
  text-decoration: none !important;
}
.minCard {
  max-height: 400px;
  overflow-y: scroll;
}
/* Admin */
.main-color {
  background: #0744e1;
}
.main-admin-btn {
  color: #fff;
  background-color: #0744e1;
}
.main-admin-btn:hover {
  background-color: #5180fa;
  color: #fff;
}
.second-color {
  background-color: #020a58;
}
.second-text {
  color: #020a58;
}
.second-outline {
  border: solid 1.5px#020A58;
  color: #020a58;
}
.second-outline:hover {
  background-color: #020a58;
  color: #fff;
}
.second-outline .dark-filter:hover,
.danger-filter:hover {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(121deg)
    brightness(107%) contrast(101%);
}
.light-main {
  background-color: #002cfd;
}
.danger-color {
  background-color: #c81111;
}
.danger-outline {
  border: solid 1.5px#C81111;
  color: #c81111;
}
.danger-outline:hover {
  background-color: #c81111;
  color: #fff;
}
.danger-btn {
  color: #fff;
  background-color: #c81111;
}
.danger-btn:hover {
  background-color: #e26363;
  color: #fff;
}
.cancel-btn {
  color: #000;
  background-color: #e4e4e4;
}
.cancel-btn:hover {
  background-color: #c5c5c5;
  color: #fff;
}
.shadow-1 {
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.25);
}
.dark-filter {
  filter: invert(58%) sepia(6%) saturate(6590%) hue-rotate(240deg)
    brightness(26%) contrast(101%);
}
.danger-filter {
  filter: invert(11%) sepia(96%) saturate(4681%) hue-rotate(357deg)
    brightness(73%) contrast(81%);
}
.w-15 {
  width: 15%;
}
.w-33 {
  width: 33%;
}
.fs-7 {
  font-size: 14px;
}
.normal-admin-card {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.header-admin {
  width: 16%;
  background: #020a58;
  padding: 20px;
  z-index: 1;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.header-admin ul {
  width: 100%;
}
.header-admin a {
  text-decoration: none;
}
.logo {
  height: 100px;
}
.menu {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  display: inline-grid;
  width: 100%;
}
.menu-admin-list:hover {
  color: #050958 !important;
}
.menu-admin-list.active {
  background: #fff !important;
  color: #050958 !important;
  border-radius: 235px;
}
.menu-admin-list.active a {
  color: #050958 !important;
}
.nav-admin-pills .nav-link.active {
  color: #050958 !important;
  border-radius: 20px;
  background: #fff !important;
}
.active-admin i {
  color: #050958 !important;
}
.nav-admin-item.menu-admin-list li.active {
  color: #050958 !important;
}

/****Admin Table****/
.headercenter {
  text-align: center;
}
.user-option {
  margin-bottom: 20px;
}
.lock {
  border-radius: 50%;
  padding: 10px 14px;
}
.locked,
.lock:hover {
  background-color: #002cfd;
  border-radius: 50%;
  padding: 10px 14px;
}
.locked .dark-filter,
.lock .dark-filter:hover {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(121deg)
    brightness(107%) contrast(101%);
}
.locked:hover {
  background-color: #002cfd;
}
.token-card:hover {
  border: 2px solid #002cfd;
  color: #002cfd;
}
.token-card:active {
  border: 2px solid #002cfd;
  color: #002cfd;
}
.token-card-active {
  border: 2px solid #002cfd;
  color: #002cfd;
}
/**********Admin SUBSCRIPTION*************/

/* LOGIN */

.login-bg {
  width: 100%;
  height: 100%;
}
.login {
  width: 35%;
}
.btn-outline-primary,
.primary-color {
  border-color: #0744e1 !important;
  text-align: center;
}
.btn-outline-primary {
  color: #0744e1 !important;
  text-align: center;
}
.script-btn:hover {
  background-color: #8bacff !important;
}
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #0744e1 !important;
}
.btn-check:checked + .btn {
  color: #fff !important;
  background-color: #0744e1 !important;
  border-color: #0744e1 !important;
}
.login-otherbtn {
  font-size: 14px;
  font-weight: 500;
  color: #585858;
  margin-bottom: 0px !important;
}
.btn {
  display: flex !important;
  align-items: center;
  text-align: center !important;
  justify-content: center;
}

input[type="file"] {
  display: none;
}
.trial-button {
  background-color: #fff;
  border-radius: 50rem;
}
.trial-button:hover {
  background-color: #fff;
}
.trial-button-title {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
}
.trial-button-time {
  color: #020a58;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
}
.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  color: #0744e1;
  text-decoration: underline;
  font-weight: 500;
}
.custom-icon-upload {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
}

.header {
  background: #020a58;
  z-index: 1;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  overflow: hidden;
}
.header ul {
  width: 100%;
}
.header a {
  text-decoration: none;
}
.logo {
  height: 100px;
}
.menu {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  display: inline-grid;
  width: 100%;
}
.menu-list.active {
  background: #fff !important;
  color: #050958 !important;
  border-radius: 235px;
}
.menu-list.active a {
  color: #050958 !important;
  font-weight: 400 !important;
}

.menu-admin-list.active-admin {
  background: #fff !important;
  color: #050958 !important;
  border-radius: 235px;
}
.menu-admin-list.active-admin a {
  color: #050958 !important;
  font-weight: 400 !important;
}

.nav-pills .nav-link.active {
  color: #050958 !important;
  border-radius: 20px;
  background: #fff !important;
}
.active i {
  color: #050958;
}

.nav-item.menu-list li.active {
  color: #050958 !important;
}
.btn-outline-primary:hover img {
  filter: brightness(0) invert(1);
}
.btn-check:checked + .btn img {
  filter: brightness(0) invert(1);
}
.menu-list {
  display: inline-flex;
  padding: 10px;
  width: 100%;
  gap: 12px;
  font-size: 13px;
}
.menu-list a {
  color: #fff;
  padding: 0;
  font-weight: 400;
}

.menu-list .sidebar-dropdown-item {
  padding: 0;
  font-weight: 400;
}

.menu-list a:hover {
  text-decoration: none;
  color: #fff !important;
}
.menu-admin-list {
  display: inline-flex;
  padding: 10px;
  width: 100%;
  gap: 12px;
  font-size: 13px;
}
.menu-admin-list a {
  color: #fff;
  padding: 0;
  font-weight: 400;
}

.menu-admin-list .sidebar-dropdown-item {
  padding: 0;
  font-weight: 400;
}

.menu-admin-list a:hover {
  text-decoration: none;
  color: #fff !important;
}
.menu-icon {
  font-size: 36px;
  color: #fff;
}
.more-icon {
  font-size: 20px;
  color: #fff;
}
.arrow-icon {
  font-size: 20px;
  color: #fff;
}
.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 20px;
}
.sidebar-container .logo-sidebar {
  /* text-align: center;
  align-self: center; */
  margin-bottom: 10px;
  margin-top: 10px;
  max-width: 250px;
  max-height: 100px;
}
.sub-navlink {
  padding: 7px !important;
}

@media screen and (max-width: 1560px) {
  .sidebar-container {
    padding: 10px;
    font-size: 15px;
  }
  .nav-item span {
    font-size: 15px;
  }
  .menu-list {
    padding: 5px;
  }
  .color-input-label {
    font-size: 12px !important;
    text-wrap: nowrap;
  }
  .chat-setting-nav-buttons {
    width: 100% !important;
  }
}
@media screen and (max-width: 1300px) {
  .sidebar-container {
    padding: 8px;
    font-size: 12px;
  }
  .nav-item span {
    font-size: 12px;
  }
  .sidebar-container .logo-sidebar {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.account-menu {
  font-size: 14px;
  color: #020a58;
  position: relative;
  top: 60%;
  background: #fff;
  border-radius: 15px;
}
.account-icon {
  font-size: 32px;
  color: #020a58;
}

.with-toggle {
  justify-content: space-between;
}

.settings {
  display: inline-flex;
  gap: 15px;
  align-items: center;
}

/********************DASHBOARD****************/

.page-title {
  gap: 20px;
  padding: 20px 10px;
  display: inline-flex;
  width: 100%;
  align-items: center;
}
.main-title {
  width: 70%;
  margin-top: 6px;
}
.main-title h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.main-title span {
  font-size: 12px;
  font-weight: 200;
}

.filter {
  display: inline-flex;
  justify-content: flex-end;
  gap: 20px;
}

.user-filter {
  background: #fff;
  width: 150px;
  height: 40px;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
  display: inline-flex;
  align-items: center;
  padding: 5px;
}
.all {
  background: #0744e1;
  border-radius: 5px;
  width: 50%;
  height: 30px;
  color: #fff;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.group {
  border-radius: 5px;
  width: 50%;
  height: 30px;
  color: #061349;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.month {
  background: #fff;
  border: none;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
  width: 150px;
  height: 40px;
  color: #061349;
  font-size: 14px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.data-summary {
  margin: 20px 0;
  display: inline-flex;
  width: 100%;
}
.data-cards {
  width: 100%;
  background: #fff;
  margin: 0 2% 0 0;
  padding: 10px;
  border-radius: 6px;
  display: grid;
}

.data-cards span {
  font-size: 12px;
  font-weight: 400;
  height: 30px;
}

.data-cards h3 {
  font-size: 25px;
  font-weight: 600;
  color: #020a58;
}

.data-cards img {
  width: 25px;
  position: relative;
  top: -6px;
}
.total-tokens {
  display: inline-flex;
  justify-content: space-between;
}
.add-token {
  position: relative;
  top: -5px;
  width: 28px !important;
}

.result {
  display: inline-flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}

.data-charts,
.data-tables,
.data-ratings {
  margin-bottom: 20px;
}

.title-chart {
  display: inline-flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.title-chart h6 {
  font-weight: 500;
}
.option-btns {
  display: inline-flex;
  gap: 10px;
}
.option-btns img {
  width: 21px;
}
.option-btns button {
  padding: 3px;
}

.chart-result img,
.table-result img {
  width: 100%;
}

.consumption,
.topics,
.questions,
.received,
.ratings {
  background: #fff;
  padding: 25px;
}
.consumption {
  height: 500px;
}

.table-name {
  font-size: 13px;
  font-weight: 600;
  padding: 5px !important;
}

.table-details {
  font-weight: 500;
  font-size: 14px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: #0067ff14 !important;
}

.ques-res {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  max-width: 500px;
  font-weight: 400;
}

.rating-result {
  display: inline-flex;
  gap: 6px;
}

.total-result {
  display: grid;
  justify-items: center;
}

.total-result h1 {
  font-size: 100px;
  margin-bottom: -20px;
}
.total-result img {
  width: 85%;
}

.chart-rating {
  width: 45%;
}
.stars {
  display: grid;
  padding: 0 10px;
  margin-top: 10px;
}

/*****Modal********/
.modal-header {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px 15px 0px 0px;
  border: none !important;
}
.modal-header h4 {
  font-size: 18px;
}
.modal-option img {
  width: 18px;
}
.modal-option button {
  padding: 3px;
}

.tokens {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px 27px;
}
.token-title {
  font-size: 12px;
}
.token-card {
  display: inline-flex;
  width: 47%;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  padding: 13px;
  gap: 10px;
}
.token-card img {
  width: 35px;
}
.token-details h3 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 8px;
}
.token-details span {
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: -5px;
}

/******TABLES**********/
.table-responsive-sm {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/******************USERS*****************/
.user-option {
  margin-bottom: 20px;
}

.user-opt-btns {
  width: 45%;
  margin-top: 15px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.res-opt-btns {
  width: 50%;
  margin-top: 15px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.add-user {
  display: inline-flex;
  width: 100%;
  gap: 15px;
  justify-content: flex-end;
}
.add-user a {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.add-user img {
  width: 25px;
}
.active-bt {
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 12px 0 !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}
.inactive-bt {
  background: #ffffff !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #666 !important;
  padding: 12px 0 !important;
}
.table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.table-tile {
  background: #fff;
  border-radius: 15px;
}

.table-header {
  font-size: 14px;
  font-weight: 600;
}

.userTableHeader .table-header:nth-child(5) {
  text-align: center;
}

.userTableHeader .table-header:first-child {
  padding-left: 40px;
}
.tablecenter td {
  text-align: inherit;
}
.headercenter {
  text-align: center;
}

.name {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  vertical-align: middle;
}

.user-name {
  display: inline-flex;
  gap: 20px;
  align-items: center;
}
.user-name span {
  font-weight: 500;
  font-size: 15px;
  width: max-content;
}

.table-details {
  display: table-cell;
  vertical-align: middle !important;
}
.table-details span {
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action-option {
  display: inline-flex;
  gap: 10px;
}
.tag {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.role {
  border-radius: 38px;
  padding: 12px;
  width: 50%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.owner {
  color: #fff;
  background-color: #0744e1;
  border: 1.2px solid #0744e1;
  width: 113px;
  border-radius: 38px;
  padding: 6px 13px;
}
.superAdmin {
  color: #264be1;
  background-color: #f6f8ff;
  border: 1.2px solid #264be1;
  width: 113px;
  border-radius: 38px;
  padding: 6px 13px;
}
.subscriptionMaster {
  color: #8f26e1;
  background-color: #fdf6ff;
  border: 1.2px solid #8f26e1;
  width: 170px;
  border-radius: 38px;
  padding: 6px 17px;
}
.resourceManager {
  color: #da0000;
  background-color: #ffeaea;
  border: 1.2px solid #da0000;
  width: 167px;
  border-radius: 38px;
  padding: 6px 17px;
}
.dataAnalyst {
  color: #a14400;
  background-color: #fff4ea;
  border: 1.2px solid #a14400;
  width: 130px;
  border-radius: 38px;
  padding: 6px 17px;
}
.userAdmin {
  color: #00a106;
  background-color: #edffea;
  border: 1.2px solid #00a106;
  width: 115px;
  border-radius: 38px;
  padding: 6px 17px;
}
.admin {
  color: #264be1;
  background-color: #f6f8ff;
  border: 1.2px solid #264be1;
  width: 74px;
  border-radius: 38px;
  padding: 6px 13px;
}
.usertag {
  color: #0095a1;
  background-color: #eafdff;
  border: 1.2px solid #0095a1;
  width: 72px;
  border-radius: 38px;
  padding: 6px 17px;
}
.role span {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.user-btn {
  padding: 0 !important;
}

/* .super-admin {
  background: #f7f8ff;
  border: 1.2px solid #264be1;
  color: #264be1;
}

.user-admin {
  background: #edffea;
  border: 1.2px solid #00a106;
  color: #00a106;
}

.master {
  background: #fdf6ff;
  border: 1.2px solid #8f26e1;
  color: #8f26e1;
  width: 75%;
}

.analyst {
  background: #fff4ea;
  border: 1.2px solid #a14400;
  color: #a14400;
  width: 55%;
}

.owner {
  background: #0744e1;
  border: 1.2px solid #0744e1;
  color: #fff;
  width: 40%;
}

.manager {
  background: #ffeaea;
  border: 1.2px solid #da0000;
  color: #da0000;
  width: 75%;
}

.admin {
  background: #f6f8ff;
  border: 1.2px solid #264be1;
  color: #264be1;
  width: 40%;
}

.user {
  background: #eafdff;
  border: 1.2px solid #0095a1;
  color: #0095a1;
  width: 40%;
} */

.paging {
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

/*************GROUPS**************/
.group-list {
  margin-top: 10px;
}
.group-card {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 30px;
  width: 100%;
  height: 270px;
}
.group-name {
  margin-bottom: 4px;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.group-name h5 {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.group-count {
  margin-top: 10px;
  border-top: 1px solid #ececec;
  font-size: 14px;
  padding: 10px 0;
}
.group-count span {
  font-weight: 500;
}
.group-topics span {
  border: 0.9px solid #535353ea;
  padding: 2px 5px 2px 5px;
  border-radius: 20px;
  font-size: 13px;
  color: #535353;
}
.group-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.users-group {
  margin: 0px 0 20px 0;
  display: inline-flex;
  gap: 10px;
}
.users-group img {
  width: 40px;
}
.remove-usergrp {
  cursor: pointer;
}
.other-user {
  width: 40px;
  background: #0744e1;
  color: #fff;
  font-weight: 500;
  text-align: center;
  padding: 5px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

/****Chat Settings*****/
.chatset-title h6 {
  font-weight: 600;
  font-size: 18px;
}

.scrollable-select option {
  overflow: auto;
  min-height: 100px;
}

.switch-ai-g {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.no-margin {
  margin: 0 !important;
  font-size: 14px;
  font-weight: 500;
}

.w-message {
  font-size: 10px;
  font-weight: 400;
  margin: 0 !important;
}
.cont-wmessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.tb-rw-message {
  font-size: 14px;
  font-weight: 600;
}

.chatsettings-headers .tb-rw-message:nth-child(2) {
  text-align: center;
}
.tb-rw-messages {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.search-inp2 {
  width: 90%;
  padding: 5px 10px 5px 15px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  color: #000;
}
.search-inp2::placeholder {
  font-size: 13px;
}

.inp-3 {
  padding: 10px 10px 10px 55px !important;
}

.search-inp2[type="text"] {
  font-size: 13px;
}

.addcategory {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.color-pick2 {
  display: flex;
  background: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  padding: 5px 5px;
  gap: 13px;
}

/*****************MODAL*************************/

.modal-body {
  overflow-y: auto;
}

/*****User Profile******/
.side {
  margin: 0 auto !important;
  margin-right: 0 !important;
  overflow-x: auto;
}
.side-content {
  height: 100%;
}
.header-profile {
  width: 100%;
  display: inline-grid;
  justify-items: center;
  gap: 10px;
}
.header-profile img {
  /* width: 30%; */
}

.nav-chat {
  margin: 17px 0 8px -10px !important;
}

.nav-tabs {
  margin: 17px 0 8px 0;
  border-bottom: 1px solid #d2d6da;
}
.group-modal {
  height: 100vh;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  font-weight: 300;
  font-size: 16px;
}

.nav-item > .nav-link.active {
  border-bottom: unset !important;
}

li > .nav-link.active {
  border-bottom: unset !important;
}

.nav-link.active {
  color: #0744e1 !important;
  background-color: #fff;
  border-color: #fff #fff #0744e1 !important;
  border-radius: 0;
  font-weight: 500 !important;
  border-bottom: 3px solid !important;
  border: 0;
}

.details-name span {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-right: 5px;
}
.details-name p {
  font-size: 15px;
  color: #000;
  font-weight: 300;
}
.pass-view {
  width: 100%;
  display: inline-flex;
  gap: 10px;
  justify-content: space-between;
}
.pass-view img {
  width: 24px;
  margin-top: -25px;
}

.profile-option {
  display: inline-grid;
  width: 100%;
}

.action-btn {
  display: inline-flex !important;
  width: 100%;
  align-items: center;
  gap: 10px;
  color: #061349;
  margin: 10px 0;
  padding: 0 !important;
}
.action-btn img {
  width: 25px;
}
.delete {
  filter: invert(13%) sepia(94%) saturate(7466%) hue-rotate(0deg)
    brightness(94%) contrast(115%);
}

.editProfile {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 10px 40px;
}
.profile-field {
  width: 45%;
  margin-bottom: 0.7rem !important;
}
.profile-long {
  width: 100%;
  margin-bottom: 0.7rem !important;
}
.profile-input {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
  border-radius: 38px !important;
  height: 40px !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  color: #000 !important;
  border: 0 !important;
}

.details {
}
label {
  display: inline-block;
  margin-bottom: 0.3rem !important;
  font-size: 14px !important;
  color: #000;
}
.modal-footer {
  align-items: center !important;
  justify-content: center !important;
}

/*********GROUP**********/
.group-btn {
  width: 100%;
  display: inline-flex;
  text-align: inherit;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
}
.count-search {
  margin-bottom: 10px;
  display: grid;
  gap: 5px;
}
.search-inp {
  width: 100%;
  padding: 15px 15px 15px 55px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  color: #000;
}
.search-ic {
  position: absolute;
  width: 25px !important;
  margin-top: 15px;
  margin-left: 20px;
}
.search-ic2 {
  position: absolute;
  width: 25px !important;
  margin-top: 10px;
  margin-left: 20px;
}

.admin-name,
.member-name {
  display: inline-flex;
  gap: 20px;
  align-items: center;
}

.admin-name img,
.member-name img {
  width: 50px !important;
  border-radius: 50%;
  height: 50px;
}

.admin-card,
.member-card {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.user-image {
  border-radius: 50%;
  height: 125px;
  width: 125px;
}
.group-imageic {
  border-radius: 50%;
  height: 55px;
  width: 55px;
  object-fit: cover;
}
.tag-card-grp {
  margin-right: 5px;
  margin-top: 2px;
  font-size: 12px !important;
}
.tags-card-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.main-btn {
  background-color: #0744e1;
  height: 45px;
  border-radius: 20px !important;
  color: #fff;
  font-weight: 500 !important;
  text-align: center;
  border: 0;
}
.main-btn:hover {
  background-color: #0744e1;
  height: 45px;
  border-radius: 20px !important;
  color: #fff;
  font-weight: 500 !important;
  text-align: center;
  border: 0;
}
.main-bg {
  background-color: #0744e1 !important;
}
.main-text {
  color: #0744e1 !important;
}
.next-text {
  color: #050958 !important;
  font-size: 13px;
}
.light-btn {
  color: #0744e1 !important;
  background: #dff4ff !important;
}
.btn-secondary {
  width: 100%;
  border-radius: 20px !important;
}
.main-dash {
  border-radius: 68px !important;
  border: 1px dashed #0033ad !important;
}

.normal-btn {
  width: 45%;
  height: 40px;
  color: #000 !important;
  font-weight: 300 !important;
  background-color: #dfdfdf !important;
  border-radius: 20px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.delete-btn {
  width: 45%;
  height: 40px;
  color: #fff !important;
  font-weight: 300px !important;
  border-radius: 20px !important;
  background-color: #c81111 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/*******NEW GROUP******/
.add-group,
.data-option,
.add-members,
.normal-card {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.profile-card {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 600px;
}
.add-members {
  height: calc(100vh- 20%);
}

.add-group h5,
.data-option h5 {
  padding-bottom: 16px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dee2e6;
  font-weight: 600;
}
.group-img {
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
}
.new-group-name {
  display: inline-flex;
  gap: 20px;
  width: 100%;
  padding: 10px;
}
.new-group-name img {
  border-radius: 50%;
  height: 125px;
  width: 125px;
}
.group-img a {
  font-size: 14px;
  text-decoration: underline !important;
  color: #0744e1 !important;
}

.newgroup-field {
  width: 100%;
}
.newgroup-input {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
  border-radius: 38px !important;
  height: 50px !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  color: #000 !important;
  border: 0 !important;
  padding: 1.375rem 1.75rem !important;
}

.group-label {
  font-size: 16px !important;
  font-weight: 300;
  color: #000;
  margin-bottom: 14px !important;
}
.group-members-list {
  margin-top: 10px;
  overflow-y: scroll;
  height: calc(100vh - 400px);
}
.remove-btn {
  filter: grayscale(100%);
}

/******CHAT-CONSOLE*******/
.area {
  display: inline-flex;
  width: 100%;
}
.chat-console {
  background: #ffffff;
  box-shadow: 5px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 15px 0px 0px 15px;
  z-index: 1;
  margin-bottom: 0;
  min-height: 100%;
}
.fave h6 {
  font-weight: 600;
  margin-top: 20px;
}
.favorite-list {
  max-height: 150px;
  overflow-y: auto;
}
.new-count {
  font-size: 13px;
  width: 25px;
  height: -5%;
  height: 23px;
}
.new h6 {
  font-weight: 600 !important;
}
.new span {
  color: #000 !important;
  white-space: pre;
}
.direct-card,
.individual-card {
  padding: 10px;
  display: inline-flex;
  gap: 3px;
  margin: 5px 0;
  align-items: center;
  width: 100%;
}
.groups-card {
  padding: 10px;
  gap: 12px;
  margin: 5px 0;
  align-items: center;
  width: 100%;
}
.group-info {
  width: 85%;
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: normal;
  color: #000;
}
.direct-info {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
}
.direct-info h6 {
  font-weight: 500;
  margin-top: 0;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
}

.direct-info span {
  font-size: 11px;
  white-space: pre;
  color: #858585;
}
.direct-actions {
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  opacity: 0;
  position: relative;
  left: -12%;
}
.individual-card:hover .direct-actions {
  opacity: 1;
}
.individual-card:hover .individual-info h6 {
  width: 89%;
}
.direct-card:hover .direct-actions {
  opacity: 1;
  left: -1%;
}

.individual-card.active .direct-actions {
  opacity: 1;
}
.individual-card.active .individual-info h6 {
  width: 89%;
}
.individual-card.active {
  background: #edfaff;
  border-radius: 10px;
}

.individual-card:hover,
.groups-card:hover,
.direct-card:hover {
  background: #edfaff;
  border-radius: 10px;
}

.chat-dropdown-items {
  position: absolute;
  right: 0;
}

.nav-tabs .chat-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.chat-link {
  padding: 0.5rem 0.5rem;
  display: block;
  color: #000;
  font-weight: 300;
  font-size: 14px;
}
.nav-fill .nav-item,
.nav-fill > .chat-link {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.chat-link.active {
  color: #0744e1 !important;
  background-color: #fff;
  border-color: #fff #fff #0744e1 !important;
  border-radius: 0;
  font-weight: 500 !important;
  border-bottom: 3px solid !important;
  border: 0;
}
.chat-link span {
  background: #d6ebff;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50px;
}
.selected-user {
  background-color: lightgray;
  border: 2px solid lightgray;
  padding: 5px;
}
.individual-info {
  /* width: 100%; */
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: normal;
  color: #000;
}
.individual-info h6 {
  font-weight: 500;
  margin-top: 0;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  font-size: 14px;
  width: 100%;
}
.individual-info span {
  font-size: 11px;
  white-space: pre;
  color: #858585;
}

.groups-card img {
  width: 50px;
}
.chatlist {
  height: 426px;
  overflow-y: auto;
}
.normal-list {
  height: 477px;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-dropdown {
  display: inline-flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 16px !important;
  padding: 10px 15px !important;
  border-bottom: 0.5px solid #e8e8e8 !important;
}
.data-filter {
  background: #fff;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.1));
  display: inline-flex;
  align-items: center;
  padding: 5px;
  gap: 10px;
}
.chat-data-link {
  width: 30% !important;
  font-size: 11px !important;
}
.data-link {
  border-radius: 5px;
  width: 40%;
  height: 30px;
  color: #000;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.nav-fill .nav-item,
.nav-fill > .data-link {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.data-link.active {
  background: #0744e1 !important;
  color: #fff !important;
}
.chat-data {
  min-height: 20vh;
  height: 50vh;
  overflow-y: auto;
}

.chat-data .tab-content {
  /* overflow: auto;
    height: 35%; */
}
.chat-area {
  height: 100vh;
}
.chat-area {
  /* padding: 2%;
  display: inline-flex; */
  flex-direction: column;
  justify-content: space-evenly;
  /* height: 100%; */
  position: sticky !important;
  top: 0;
  background: #fff;
}
.time {
  color: #5b5b5b;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}
.apexcharts-toolbar {
  top: -25px !important;
}
.new-chat {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  height: 83%;
  justify-content: center;
  gap: 9px;
}
.chatbox {
  display: inline-flex;
  bottom: 1px;
}

.input-group {
  width: 98% !important;
}

.chat-inp {
  width: 100%;
  padding: 17px 160px 17px 65px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  color: #000;
}

.add-ic {
  position: absolute;
  width: 30px !important;
  margin-top: 20px;
  margin-left: 10px;
}
.chatbox-input {
  position: relative;
  width: 100%;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbox-input img {
  cursor: pointer;
}

.chatbox-input input {
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
  border: none;
  outline: none;
}
.send-btn {
  background: #0844e1;
  color: #fff !important;
  display: inline-flex !important;
  gap: 10px;
  font-weight: 500;
  align-items: center;
  border-radius: 50px !important;
}

.contact {
  overflow-y: auto;
  height: 681px;
}

/******CHAT-View*****/
.chat-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  height: 100vh;
}
.chat-header-mode {
  padding: 0.5rem 1.2rem !important;
  border-radius: var(--spacing-spacing-none, 0px);
  background: #f1f4ff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.chat-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: none !important;
}
.chat-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: auto;
  height: 370px;
  display: inline-flex;
  flex-direction: column;
}

.chat-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: center;
  padding: 0.75rem;
  bottom: 0;
  width: 100%;
}
.regenarate-btn {
  background: #d1e4ff !important;
  border-radius: 50px !important;
  padding: 10px 19px !important;
  margin-bottom: 5px;
  font-size: 14px !important;
}

/*******CHAT-BUBBLE**********/
.guest-bubble {
  display: inline-flex;
  justify-content: flex-end;
}

.guest-bubble p {
  position: relative;
  max-width: 34em;
  background-color: #f1f4ff;
  padding: 1.125em 1.5em;
  font-size: 15px;
  border-radius: 1rem;
}

.guest-bubble p::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  /* top: 100%; */
  /* right: 1.5em; */
  border: 2.75rem solid transparent;
  border-top: none;
  /* filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1)); */
  top: 26%;
  right: -15px;
  display: block;
  border-width: 15px 16px 26px 0px;
}

.gpt-bubble {
  display: inline-block !important;
  max-width: 34em !important;
  justify-content: flex-start;
  position: relative;
  max-width: 58em;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
  padding: 1.125em 1.5em;
  font-size: 15px;
  border-radius: 1rem;
  flex-direction: column;
  --bubble-color: #f5f5f5;
}

.gpt-bubble::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 2.75rem solid transparent;
  border-top: none;
  border-bottom-color: var(--bubble-color);
  top: 26%;
  left: -13px;
  display: block;
  border-width: 15px 23px 28px 19px;
}
/* Remove individual class-based hover styles for .one, .two, .three, .four, .five */
/* Remove individual class-based hover styles for .one, .two, .three, .four, .five */
.star {
  opacity: 0.5;
}

.star.hovered {
  filter: invert(33%) sepia(50%) saturate(2100%) hue-rotate(5deg)
    brightness(99%) contrast(98%);
}

/* Optionally, you can define a specific hover effect for the currently hovered button if needed */
.star:hover {
  /* Your specific hover styles here */
}

/* .one:hover .two {
  filter: invert(33%) sepia(50%) saturate(2100%) hue-rotate(5deg)
    brightness(99%) contrast(98%);
  opacity: 1;
} */
.chat-container {
  overflow-x: hidden;
}

/**********SUBSCRIPTION*************/
.sub-token {
  display: grid;
  gap: 7px;
}
.sub-token h3 {
  font-weight: 600;
  font-size: 34px;
}
.plan {
  display: inline-flex;
  gap: 20px;
  width: 100%;
}

.your-plan {
  display: inline-flex;
  flex-direction: column;
}
.your-plan h5 {
  font-weight: 600;
}
.your-plan p {
  font-size: 12px;
}
.your-plan a {
  font-size: 13px;
  font-weight: 100;
  text-decoration: underline !important;
}
.auto-renew {
  display: inline-flex;
  gap: 10px;
  align-items: center;
  margin: 6px 0;
}
.auto-renew span {
  font-size: 14px;
}
.form-switch .form-check-input {
  width: 50px !important;
  height: 29px !important;
  cursor: pointer;
}
.features {
  display: inline-flex;
  gap: 90px;
}
.feature-list {
  display: inline-flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.f-list,
.includes {
  display: inline-flex;
  flex-direction: column;
}
.add-ons {
  padding: 20px 0;
  border-top: 0.5px solid #d6d6d6;
  margin-top: 20px;
  display: inline-flex;
  flex-direction: column;
}
.members-list2 {
  overflow-y: scroll;
  height: calc(100vh - 500px);
}

.main-border-btn {
  padding: 10px !important;
  color: #0744e1 !important;
  border: 1px solid #0744e1 !important;
  border-radius: 50px !important;
  width: 45%;
  margin-top: 10px;
  font-size: 14px !important;
}
.add-on-price {
  display: inline-flex;
  width: 100%;
  align-items: flex-start;
}
.add-on-card {
  display: inline-flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #515151;
  border-radius: 20px;
}
.add-on-card span {
  font-size: 14px;
  text-align: start;
}

.active-plan {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
  color: #0744e1;
}
.tier-features span {
  font-size: 12px;
}
.tier-card {
  width: 32%;
}
.tier-list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/********TOKEN RESTRICTION*********/
.num-inp {
  height: 50px;
  width: 160px;
  border-radius: 30px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 19px;
  font-weight: 600;
}
.num-inp2 {
  height: 30px;
  width: 160px;
  border-radius: 30px;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 19px;
  font-weight: 600;
}

.selected-bot {
  color: #fff !important;
  background-color: #0744e1 !important;
}

.default-bot {
  color: #fff !important;
  background-color: #0744e1 !important;
}

.selected-bot > img {
  filter: invert(1);
}

.tok-restrict {
  width: 50%;
}
.tok-restrict span {
  font-size: 12px;
}

.title-token-w {
  font-weight: 500;
}

.group-card-cont-header {
  display: flex;
  flex-direction: column;
}
.tag2 {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
}
.role-tok {
  border: 1px solid rgba(0, 0, 0, 0.237);
  border-radius: 38px;
  padding: 10px;
  width: 80px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.group-card-rest {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 10px 20px 20px 20px;
  margin-bottom: 30px;
  width: 100%;
  height: 250px;
}
/******BRANDING******/

.form-control-color {
  width: 3rem !important;
  height: 30px;
}
.bot {
  width: 80px;
  height: 80px;
  padding: 12px !important;
  display: flex !important;
  align-items: center;
}
.color-pick {
  /* height:150px; */
  display: flex;
  background: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  padding: 6px 11px;
  gap: 13px;
}
.chatbox-preview {
  background: #ffffff;
  border-radius: 23px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}
.chatbox-header {
  border-radius: 23px 23px 0px 0px;
  padding: 15px;
  background-color: #e1e7ff;
}
.chatbox-body .gpt-bubble {
  justify-content: flex-start;
  position: relative;
  max-width: 47em;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
  padding: 15px 13px 14px 13px;
  font-size: 16px;
  border-radius: 1rem;
  flex-direction: row;
  align-items: center;
}

.fw-medium {
  font-weight: 500 !important;
}

.chatbox-body .gpt-bubble::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 2.75rem solid transparent;
  border-top: none;
  border-bottom-color: #f5f5f5;
  top: 32%;
  left: -15px;
  display: block;
  border-width: 2px 15px 11px 15px;
}

.chatbox-body .guest-bubble {
  display: inline-flex;
  white-space: break-spaces;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 29px;
  margin-bottom: 10px;
}

.chatbox-footer .chat-inp {
  width: 100%;
  padding: 17px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 38px !important;
  color: #000;
}
.chatbox-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: center;
  padding: 0.75rem;
}

.chatbox-action {
  position: absolute;
  right: 22px;
  display: inline-flex;
  margin-top: 0;
}
.welcome-msg span {
  border: none;
  padding: 9px;
  border-radius: 20px;
  font-size: 13px;
  color: #535353;
  background: #e2eaff;
}
.msg-remove {
  filter: invert(17%) sepia(61%) saturate(4902%) hue-rotate(226deg)
    brightness(92%) contrast(105%);
}
.login-view {
  border-radius: 23px;
  background: #f6f8ff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  height: 471px;
}
.bot-main-title {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/******MANAGE MATERIALS*****/
.folder {
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  padding: 25%;
  width: 114px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-border {
  border-radius: 3px;
  border: 2px dashed #000;
  padding: 25px;
}
.qna-area {
  width: 88%;
}
.qna {
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  width: 49%;
  padding-right: 15px;
  margin-right: 13px;
}
.qna-view {
  width: 49%;
  padding-right: 15px;
  margin-right: 13px;
  overflow-wrap: anywhere;
}
modal-content .sidebar-dropdown-item {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.sidebar-user-dropdown {
  position: absolute;
  inset: auto auto 0px 0px;
  top: auto;
  right: auto;
  bottom: 0px;
  left: 0px;
  margin: 0px;
  transform: translate3d(0px, -56px, 0px);
}

.error-message {
  padding-top: 5px;
  padding-left: 10px;
  font-size: 12px;
  color: red;
}
.user-dropdown {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(0px, 21.6px, 0px);
}

.group-initials {
  border-radius: 50%;
  min-height: 55px;
  min-width: 55px;
  background-color: #cee5ff;
  text-align: center;
  color: #a0a1a1;
  font-size: 19px;
  font-weight: 500;
  padding-top: 15px;
  margin: 0 !important;
}
.model-numbers {
  border-radius: 50%;
  min-height: 45px;
  min-width: 45px;
  background-color: #94d1ff;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
  padding-top: 8px;
  margin: 0 !important;
}
.group-initials2 {
  border-radius: 50%;
  height: 125px;
  width: 125px;
  background-color: #cee5ff;
  text-align: center;
  color: #a0a1a1;
  font-size: 19px;
  font-weight: 500;
  padding-top: 45px;
  margin: 0 !important;
}
.images-group-card {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 5px;
}

.remaining-members {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 10px;
  margin-left: 5px;
  background-color: #002cfd;
  color: white;
  font-size: 10px;
}
.remaining-tags {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 10px;
  margin-left: 5px;
  margin-top: 2px !important;
  color: white;
  font-size: 10px;
}
.remaining-tags-cont {
  margin-top: 2px !important;
}
/************WALKTHROUGH*********/

/* (A) SPEECH BOX */
.speech {
  /* (A1) FONT */
  font-size: 1.1em;

  /* (A2) COLORS */
  color: #000;
  background: #fff;

  /* (A3) DIMENSIONS + POSITION */
  position: relative;
  padding: 20px;
  border-radius: 10px;
  margin: 50px auto;
  max-width: 100%;
}

/* (B) ADD SPEECH "CALLOUT TAIL" */
/* (B1) USE ::AFTER TO CREATE THE "TAIL" */
.speech::after {
  /* (B1-1) ATTACH TRANSPARENT BORDERS */
  content: "";
  border: 20px solid transparent;

  /* (B1-2) NECESSARY TO POSITION THE "TAIL" */
  position: absolute;
}

/* (B2) BOTTOM "CALLOUT TAIL" */
.bottom.speech::after {
  /* (B2-1) DOWN TRIANGLE */
  border-top-color: #fff;
  border-bottom: 0;

  /* (B2-2) POSITION AT BOTTOM */
  bottom: -20px;
  left: 50%;
  margin-left: -20px;
}

/* (C) DIFFERENT TAIL POSITIONS */
/* (C1) TOP */
.top.speech::after {
  /* (C1-1) UP TRIANGLE */
  border-bottom-color: #fff;
  border-top: 0;

  /* (C1-2) POSITION AT TOP */
  top: -15px;
  left: 50%;
  margin-left: -20px;
}
.topuser.speech::after {
  /* (C1-1) UP TRIANGLE */
  border-bottom-color: #fff;
  border-top: 0;

  /* (C1-2) POSITION AT TOP */
  top: -15px;
  left: 90%;
  margin-left: -20px;
}

/* (C2) LEFT */
.left.speech::after {
  /* (C2-1) LEFT TRIANGLE */
  border-right-color: #fff;
  border-left: 0;

  /* (C2-2) POSITION AT LEFT */
  left: -20px;
  top: 50%;
  margin-top: -20px;
}

/* (C3) RIGHT */
.right.speech::after {
  /* (C3-1) RIGHT TRIANGLE */
  border-left-color: #fff;
  border-right: 0;

  /* (C3-2) POSITION AT RIGHT */
  right: -20px;
  top: 50%;
  margin-top: -134px;
}
.rightgroup.speech::after {
  /* (C3-1) RIGHT TRIANGLE */
  border-left-color: #fff;
  border-right: 0;

  /* (C3-2) POSITION AT RIGHT */
  right: -18px;
  top: 36%;
  margin-top: -134px;
}

.wt-token {
  margin-left: 43% !important ;
  margin-top: 2% !important ;
}

.wt-plan {
  margin-left: 28% !important;
  margin-top: 14% !important;
}
.wt-user {
  margin-left: 30% !important;
  margin-top: 6% !important;
}
.c-plan {
  margin-left: 45% !important;
  margin-top: 4% !important ;
}
.gt-plan {
  margin-left: 40% !important;
  margin-top: 1% !important ;
}
.chatt-plan {
  margin-left: 40% !important;
  margin-top: 16% !important ;
}
.chat-svg-walk {
  width: 300px;
}
.material-plan {
  margin-left: 35% !important;
  margin-top: 3% !important ;
}
.script-plan {
  margin-left: 47% !important;
  margin-top: 6% !important ;
}
.settings-plan {
  margin-left: 41% !important;
  margin-top: 6% !important ;
}
.token-plan {
  margin-left: 42% !important;
  margin-top: 15% !important ;
}
@media screen and (max-width: 1732px) {
  .c-plan {
    margin-left: 42% !important;
  }
  .wt-user {
    margin-left: 25% !important;
  }
  .settings-plan {
    margin-left: 35% !important;
  }
  .token-plan {
    margin-top: 17% !important ;
  }
  .chatt-plan {
    margin-left: 40% !important;
    margin-top: 20% !important ;
  }
}

@media screen and (max-width: 1668px) {
  .wt-token {
    margin-left: 35% !important ;
    margin-top: 3% !important ;
  }
  .c-plan {
    margin-left: 39% !important;
    margin-top: 5% !important ;
  }
  .gt-plan {
    margin-left: 30% !important;
  }
  .material-plan {
    margin-left: 30% !important;
    margin-top: 3% !important ;
  }
}
@media screen and (max-width: 1554px) {
  .c-plan {
    margin-left: 35% !important;
    margin-top: 4% !important ;
  }
  .gt-plan {
    margin-left: 25% !important;
  }
  .script-plan {
    margin-left: 40% !important;
    margin-top: 7% !important ;
  }
  .settings-plan {
    margin-left: 30% !important;
    margin-top: 6% !important ;
  }
}
@media screen and (max-width: 1512px) {
  .wt-token {
    margin-left: 30% !important ;
    margin-top: 6%;
  }
  .wt-user {
    margin-left: 19% !important;
    margin-top: 8% !important ;
  }
  .gt-plan {
    margin-left: 20% !important;
  }
  .material-plan {
    margin-left: 25% !important;
  }
  .script-plan {
    margin-left: 35% !important;
    margin-top: 7% !important ;
  }
  .settings-plan {
    margin-left: 23% !important;
    margin-top: 7% !important ;
  }
  .token-plan {
    margin-top: 20% !important ;
    margin-left: 39% !important;
  }
}

@media screen and (max-width: 1480px) {
  .c-plan {
    margin-left: 28% !important;
    margin-top: 4% !important ;
  }
  .chatt-plan {
    margin-left: 40% !important;
    margin-top: 24% !important ;
  }
}
@media screen and (max-width: 1300px) {
  .wt-token {
    margin-left: 25% !important ;
    margin-top: 6.3% !important ;
  }
  .c-plan {
    margin-left: 25% !important;
    margin-top: 5.5% !important ;
  }
  .chatt-plan {
    margin-left: 28% !important;
    margin-top: 24% !important ;
  }
  .material-plan {
    margin-left: 18% !important;
  }
}
@media screen and (max-width: 1250px) {
  .c-plan {
    margin-left: 20% !important;
    margin-top: 5.5% !important ;
  }
  .wt-user {
    margin-left: 14% !important;
    margin-top: 8% !important ;
  }
}

@font-face {
  font-family: "icomoon";
  /* src:  url('/public/fonts/icomoon.eot?h2qtuf'); */
  src: url("/public/fonts/icomoon.eot?h2qtuf#iefix") format("embedded-opentype"),
    url("/public/fonts/icomoon.ttf?h2qtuf") format("truetype"),
    url("/public/fonts/icomoon.woff?h2qtuf") format("woff"),
    url("/public/fonts/icomoon.svg?h2qtuf#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-subjective:before {
  content: "\e900";
  font-size: 28px;
  margin-right: 8px;
}
.icon-users:before {
  content: "\e901";
}
.icon-chat-console:before {
  content: "\e902";
}
.icon-dashboard:before {
  content: "\e903";
}
.icon-menu-arrow:before {
  content: "\e904";
}
.icon-more-arrow:before {
  content: "\e905";
}
.icon-settings:before {
  content: "\e906";
}
.icon-subscription:before {
  content: "\e907";
}
.icon-login:before {
  content: "\e908";
}
.icon-logout:before {
  content: "\e909";
}

/** Script **/
.script-table-header {
  font-size: 14px;
  font-weight: bold;
}

.script-table-body {
  font-size: 14px;
  text-align: left !important;
}

.script-label {
  font-size: 14px;
  font-weight: bold;
}

/****************************RESPONSIVE**************************/

@media (-webkit-device-pixel-ratio: 1.25) {
  .chat-data {
    height: 236px !important;
    overflow-x: hidden !important;
  }
  .new-chat {
    padding: 100px 70px !important;
  }
  .area {
    height: 750px !important;
  }
  .chat-link {
    font-size: 12px;
    padding: 0.15rem 0.15rem;
  }
  .chat-console-title {
    font-size: 21px !important;
  }
  .normal-list {
    height: 307px;
  }
}

@media (min-width: 1280px) and (max-width: 1200px) {
  /* .chat-data {
    height: 236px;
    overflow-x: hidden;
  }
  .new-chat {
    padding: 100px 70px;
  }
  .area {
    height: 750px;
  } */
}

@media (min-width: 650px) and (max-width: 1199px) {
  /* .chat-data {
    height: 236px;
    overflow-x: hidden;
  }
  .new-chat {
    padding: 100px 70px;
  }
  .area {
    height: 750px;
  } */
}

/* Laptop styles */
@media (min-width: 1200px) and (max-width: 1365px) {
  /*************DASHBOARD*************/
  /* .chat-data {
    height: 236px;
    overflow-x: hidden;
  }
  .new-chat {
    padding: 100px 70px;
  }
  .area {
    height: 750px;
  } */

  .data-cards {
    padding: 13px;
  }

  .data-cards img {
    width: 30px;
  }

  .data-cards h3 {
    font-size: 26px;
  }

  .add-token {
    width: 23px !important;
  }
  .option-btns img {
    width: 17px;
  }

  .rating-result {
    display: inline-flex;
    gap: 0px;
  }

  .total-result h1 {
    font-size: 80px;
    margin-bottom: -10px;
  }

  .total-result img {
    width: 58%;
  }

  .total-result span {
    font-size: 14px;
  }

  .chart-rating img {
    width: 100%;
  }
  .stars {
    padding: 0 2px;
    margin-top: 6px;
    gap: 5px;
  }

  .stars img {
    width: 60%;
  }

  /*********USERS*******/
  .role {
    width: 65%;
  }
  .role span {
    font-size: 11px;
  }
  .master,
  .manager {
    width: 92%;
  }
  .analyst {
    width: 70%;
  }
  .owner,
  .admin,
  .user {
    width: 55%;
  }
  .action-option {
    gap: 1px;
  }
  .action-option img {
    width: 20px;
  }

  /**************GROUPS****************/
  /* .group-name img {
    width: 44px;
  } */
  .group-name h5 {
    font-weight: 600;
    font-size: 18px;
    width: 140px;
  }
  .group-count span {
    font-size: 13px;
  }
  .users-group img {
    width: 37px;
  }
  .group-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1px;
  }
  .tag-m-right {
    border: 0.9px solid #535353ea;
    padding: 2px 5px 2px 5px;
    border-radius: 20px;
    font-size: 13px;
    color: #535353;
  }
  /***TOKEN-RESTRICTION****/
  .tok-restrict {
    width: 45%;
  }

  /******MODAL*****/
}

@media (min-width: 992px) and (max-width: 1199px) {
  /***********DASHBOARD*************/

  .data-cards {
    margin: 0 2% 20px 0;
  }

  .total-result h1 {
    font-size: 80px;
    margin-bottom: -10px;
  }

  .total-result img {
    width: 58%;
  }

  .total-result span {
    font-size: 14px;
  }
  .chart-rating {
    width: 67%;
  }

  .chart-rating img {
    width: 100%;
  }
  .stars {
    padding: 0 2px;
    margin-top: 6px;
    gap: 5px;
  }

  .stars img {
    width: 100%;
  }

  /*********USERS**********/
  .add-user button {
    font-size: 14px;
  }

  .user-name span {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
  }
  .user-name img {
    width: 35px;
  }
  .table-details span {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .role {
    width: 75%;
    text-align: center;
  }
  .role span {
    font-size: 11px;
  }
  .master,
  .manager {
    width: 100%;
  }
  .analyst {
    width: 100%;
  }
  .owner,
  .admin,
  .user {
    width: 65%;
  }

  .action-option img {
    width: 20px;
  }

  /*******SUBSCRIPTIONS*********/
  .your-plan {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 0.5px solid #d6d6d6;
    padding-bottom: 20px;
    width: 100%;
  }
  .includes {
    flex-direction: column;
  }

  .plan {
    gap: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  /******MODAL*****/
  .modal-dialog {
    width: 420px !important;
  }
  .modal-walkthrough {
    width: unset !important;
  }
}

@media (min-width: 540px) and (max-width: 991px) {
  /***********DASHBOARD*************/

  .data-cards {
    margin: 0 2% 15px 0;
  }
  .consumption,
  .topics,
  .questions,
  .received,
  .ratings {
    margin-bottom: 15px;
  }

  /********USERS***********/
  .add-user button {
    font-size: 14px;
  }
  .user-name span {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80px;
  }
  .user-name img {
    width: 35px;
  }
  .table-details span {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .role {
    width: 75%;
    text-align: center;
  }
  .role span {
    font-size: 11px;
  }
  .master,
  .manager {
    width: 100%;
  }
  .analyst {
    width: 100%;
  }
  .owner,
  .admin,
  .user {
    width: 65%;
  }

  .action-option img {
    width: 20px;
  }

  /*******SUBSCRIPTIONS*********/
  .your-plan {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 0.5px solid #d6d6d6;
    padding-bottom: 20px;
    width: 100%;
  }
  .includes {
    flex-direction: column;
  }

  .plan {
    gap: 0;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  /***TOKEN-RESTRICTION****/
  .tok-restrict {
    width: 65%;
  }

  .res-opt-btns {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 539px) {
  /***********DASHBOARD*************/

  .data-cards {
    margin: 0 2% 15px 0;
  }
  .consumption,
  .topics,
  .questions,
  .received,
  .ratings {
    margin-bottom: 15px;
  }

  /********USERS***********/
  .add-user button {
    font-size: 14px;
  }
  .table-header {
    font-size: 15px;
  }
  .user-name span {
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  .user-name img {
    width: 46px;
  }
  .table-details span {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .role {
    width: 75%;
    text-align: center;
  }
  .role span {
    font-size: 12px;
  }
  .master,
  .manager {
    width: 100%;
  }
  .analyst {
    width: 100%;
  }
  .owner,
  .admin,
  .user {
    width: 65%;
  }

  .action-option img {
    width: 20px;
  }
}
